import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Do you hear that?&nbsp;
                        <strong class="font-extrabold text-primary sm:block">
                            Éirinn is calling.
                        </strong>
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        Irishman is the cloud company of the future, leveraging 
                        data and mathematics to bring efficiency back to computing.
                    </p>

                    <div class="mt-8 flex flex-wrap justify-center gap-4">
                        <a
                            class="block w-full rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-primary focus:outline-none focus:ring active:bg-primary sm:w-auto"
                            href="/waitlist"
                        >
                            Get Started
                        </a>

                        <a
                            class="block w-full rounded px-12 py-3 text-sm font-medium text-primary shadow hover:text-primary focus:outline-none focus:ring active:text-primary sm:w-auto"
                            href="/about"
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div class="mx-auto h-full max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                        <h2 class="text-3xl font-bold sm:text-4xl">Products</h2>
                        <p class="mt-4 text-gray-600">
                            Irishman has numerous features to make the lives of you and your team easier. If you want to request a feature or are not sure about a use case, please contact us.
                        </p>

                        <div class="mt-8 flex flex-wrap justify-center lg:justify-start gap-4">
                            <a
                                class="block w-full rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-primary focus:outline-none focus:ring active:bg-primary sm:w-auto"
                                href="/products"
                            >
                                All Products
                            </a>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
                        {
                            products.map((item) => ProductIcon({ ...item, href: `/products/${item.slug}` }))
                        }
                    </div>
                </div>
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="Home" />

export default IndexPage
